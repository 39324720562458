import axios from 'axios'
import router from '../router'
import store from '../store'

// 创建axios实例
const service = axios.create({

    // baseUrl/headers
    // baseURL: '/api',
    // 设置请求超时时间
    timeout: 9000,
    validateStatus: function(status) {
        return status <= 500; // 状态码在大于或等于500时才会 reject
    }
})

// 添加请求拦截器,use(回调函数)
service.interceptors.request.use(
    config => {
        config.headers = {
            authorization: `Bearer ${localStorage.sefpc_token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    response => {
        const result = response.data;
        if (parseInt(result.status) === 90005) {
            store.commit('setUserName', '');
            store.commit('setUserId', '');
            store.commit('setToken', '');
            router.push('/login');
            console.log(result)
        } else {
            const res = {}
            res.status = response.status
            res.data = response.data
            return res
        }
    },
    err => {
        return Promise.reject(err)
    }
)

// 导出service
export default service