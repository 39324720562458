import request from '@/libs/request'

/**
 * 用户注册
 */
export function userRegisterApi(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}

/**
 * 用户登录
 */
export function userLoginApi(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

/**
 * 手机获取验证码
 */
export function getCodeApi(phone, scene) {
  return request({
    url: `/sms/send?phone=${phone}&scene=${scene}`,
    method: 'get'
  })
}

/**
 * 手机验证码登录/注册
 */
export function phoneLoginApi(data) {
  return request({
    url: '/user/quickly_login',
    method: 'post',
    data
  })
}

/**
 * 获取商家入驻协议
 */
export function getContractApi() {
  return request({
    url: '/store/entry/step_1',
    method: 'get'
  })
}

/**
 * 签署商家入驻协议
 */
export function signContractApi(data) {
  return request({
    url: '/store/entry/step_1',
    method: 'post',
    data
  })
}

/**
 * 获取商家入驻表单所需数据
 */
export function entryStep_2Api() {
  return request({
    url: '/store/entry/step_2',
    method: 'get'
  })
}

/**
 * 商家入驻表单提交
 */
export function joinFormSubmitApi(data) {
  return request({
    url: '/store/entry/step_2',
    method: 'post',
    data
  })
}

/**
 * 查看商家入驻审核进度
 */
export function joinSpeedApi() {
  return request({
    url: '/store/entry/step_3',
    method: 'get'
  })
}

/**
 * 获取文章分类
 */
export function getArticleCategoryApi(id) {
  return request({
    url: `/article/article?id=${id}`,
    method: 'get'
  })
}

/**
 * 获取文章列表
 */
 export function TwoCateApi(id) {
  return request({
    url: `/article/twocate?id=${id}`,
    method: 'get'
  })
}

/**
 * 获取文章列表
 */
export function getArticleApi(id) {
  return request({
    url: `/article/content?id=${id}`,
    method: 'get'
  })
}

