import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/element.js'

// 全局样式表
import './assets/css/global.css'
// 基础样式
import './assets/css/bass.css'
// 引入FontAwesome图标库
import 'font-awesome/css/font-awesome.min.css'
//引入iconfont图标库
import "./assets/iconfont/iconfont.css"
// import "./assets/css/gray.css"

Vue.filter('dateFormat', function(val) {

    const dt = new Date(val * 1000)

    const y = dt.getFullYear()

    const m = (dt.getMonth() + 1 + '').padStart(2, '0')

    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')

    const mm = (dt.getMinutes() + '').padStart(2, '0')

    const ss = (dt.getSeconds() + '').padStart(2, '0')

    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')