<template>
  <div class="home">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <div class="header">
          <div class="header-left">
            <span>
              <router-link to="#">
                <i class="fa fa-star-o fa-lg"></i>
                <span>收藏本站</span>
              </router-link>
            </span>
          </div>
          <div class="header-right">
            <span v-if="token"
              >欢迎您！
              <router-link to="/user">
                <el-button type="text">{{ userName || userAccount }}</el-button>
              </router-link>
            </span>
            <span v-if="token">
              <router-link to="#">
                <el-button type="text" @click="logout">退出登录</el-button>
              </router-link>
            </span>
            <span v-if="!token">
              <router-link to="/login">登录/注册</router-link>
            </span>
            <span>
              <router-link to="/user">个人中心</router-link>
            </span>
            <span>
              <router-link to="/store/join">商家入驻</router-link>
            </span>
            <span>
              <router-link to="#">联系我们</router-link>
            </span>
          </div>
        </div>
      </el-header>
      <!-- 搜索栏 -->
      <!-- 中间部分 -->
      <el-main>
        <div class="main">
          <transition name="el-zoom-in-center">
            <router-view />
          </transition>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="footer">
          <ul class="f-header">
            <li class="f-item">
              <div class="picture">
                <i class="fa fa-shopping-bag fa-lg"></i>
              </div>
              <div class="txt">品种齐全，购物轻松</div>
            </li>
            <li class="f-item">
              <div class="picture">
                <i class="fa fa-truck fa-lg"></i>
              </div>
              <div class="txt">多仓直发，极速配送</div>
            </li>
            <li class="f-item">
              <div class="picture">
                <i class="fa fa-window-restore fa-lg"></i>
              </div>
              <div class="txt">正品行货，精致服务</div>
            </li>
            <li class="f-item">
              <div class="picture">
                <i class="fa fa-cc fa-lg"></i>
              </div>
              <div class="txt">天天低价，畅选无忧</div>
            </li>
          </ul>
          <div class="f-main mt10">
            <div class="m-ul">
              <div class="m-item">
                <a href="/home">主页</a>
              </div>
              <div class="m-item">
                <a href="/home/about?id=38">十二方产业</a>
              </div>
              <div class="m-item">
                <a href="/home/admin_login">管理者登录</a>
              </div>
              <div class="m-item">
                <a href="https://serfoo.com/school/pc/home" target="_blank"
                  >职业介绍</a
                >
              </div>
              <!-- <div
                class="m-item"
                v-for="item in articleCategoryList"
                :key="item.id"
              >
                <span>
                  <router-link :to="`/home/about?id=${item.id}`">{{
                    item.title
                  }}</router-link>
                </span>
              </div> -->
            </div>
            <div class="m-txt">
              <a href="https://beian.miit.gov.cn/" target="_blank"
                >湘ICP备2021002198号-1</a
              >
            </div>
          </div>
        </div>
      </el-footer>
      <!-- 右侧固定区域 -->
      <div class="floatWindow">
        <div class="list">
          <div class="item">
            <div class="iconfont icon-lianxikefu"></div>
            <div>联系客服</div>
          </div>
          <div class="item" @mouseleave="wxCodeHide">
            <div @mouseenter="wxCode">
              <div class="iconfont icon-weixin"></div>
              <div>关注微信</div>
            </div>
            <div class="itemCon" v-if="iScode">
              <div class="ewm">
                <div class="pictrue">
                  <div class="arrow"></div>
                  <img :src="codeUrl" class="bicode" />
                </div>
                <div class="tip">扫码关注公众号</div>
              </div>
            </div>
          </div>
          <div class="item" @click="goCart">
            <div class="iconfont icon-cart"></div>
            <div>购物车</div>
          </div>
          <div class="item" @click="goTop">
            <div class="iconfont icon-huidaodingbu1"></div>
            <div>回到顶部</div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { getArticleCategoryApi } from '@/api/api'
  export default {
    name: 'Home',
    data() {
      return {
        companyInfo: {},
        codeUrl: '',
        iScode: false,
        keyword: '',
        articleCategoryList: []
      }
    },
    head() {
      return {
        meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.companyInfo.site_keywords
        },
        {
          hid: 'description',
          name: 'description',
          content: this.companyInfo.site_description
        }, ]
      }
    },
    created() {
      this.getArticleCategory()
    },
    mounted() {},
    methods: {
      // 注销登录
      logout() {
        this.$confirm('您确定退出登录吗？', '退出登录确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit('setToken', '');
          this.$store.commit('setUserId', '');
          this.$store.commit('setUserAccount', '');
          this.$store.commit('setUserName', '');
          this.$router.push('/')
          this.$message.success('已成功退出')
        }).catch(() => {
          this.$message.info('已取消');
        });
      },
      goTop() {
        (function n() {
          var t = document.documentElement.scrollTop || document.body.scrollTop;
          if (t > 0) {
            window.requestAnimationFrame(n);
            window.scrollTo(0, t - t / 5)
          }
        })()
      },
      wxCode() {
        this.iScode = true
      },
      wxCodeHide() {
        this.iScode = false
      },
      goCart() {
        this.$router.push('/home/shoppingcart');
      },
      getArticleCategory() {
        getArticleCategoryApi(0).then(res => {
          this.articleCategoryList = res.data.result
        })
      },
    },
    computed: {
      ...mapState(['userName', 'token', 'userAccount'])
    }
  }
</script>

<style lang="less" scoped>
.el-button--text {
  color: #e1251b;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}

.el-container {
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff;
}

.el-header {
  background-color: #f9f9f9;
  height: 40px !important;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  border-bottom: 1px solid #efefef;

  .header {
    width: 1200px;
    margin: 0 auto;

    a {
      color: #333;
      font-size: 12px;
    }

    a:hover {
      color: #ca151e;
    }

    .header-left {
      float: left;
      margin-left: 5px;

      span {
        margin-left: 5px;
      }
    }

    .header-right {
      float: right;

      span {
        position: relative;
        margin: 0 10px;
        font-size: 12px;

        .el-button {
          font-size: 12px;
        }
      }

      span::before {
        position: absolute;
        content: " ";
        width: 1px;
        height: 14px;
        background-color: #c0c4cc;
        right: 58px;
        top: 50%;
        margin-top: -7px;
      }

      span:first-child::before {
        display: none;
      }
    }
  }
}

.el-main {
  padding: 0;
  background: #fff;
  min-width: 1200px;
  overflow-x: auto;
}

.el-footer {
  background: #f2f2f2;
  padding: 0;
  min-height: 260px;

  .footer {
    background: #f2f2f2;
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;

    .f-header {
      height: 104px;
      border-bottom: 1px solid #e2e2e2;
      display: flex;
      justify-content: space-between;

      .f-item {
        width: 25%;
        height: 104px;
        display: flex;
        align-items: center;

        .picture {
          width: 40px;
          height: 40px;
          border: 1px solid #707070;
          border-radius: 50%;
          margin-right: 10px;
          line-height: 40px;
          text-align: center;
        }

        .txt {
          font-size: 13px;
          color: #282828;
        }
      }
    }

    .f-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      font-size: 14px;

      //   .m-ul {
      //     height: 50px;
      //     display: flex;
      //     align-items: center;

      //     .m-item {
      //       //   width: 100px;
      //       height: 25px;
      //       box-sizing: border-box;
      //       text-align: left;
      //       line-height: 25px;
      //       font-size: 14px;
      //       margin: 0px 20px;
      //       span {
      //         position: relative;
      //       }

      //       span::before {
      //         position: absolute;
      //         content: " ";
      //         width: 1px;
      //         height: 14px;
      //         background-color: #c0c4cc;
      //         right: -15px;
      //         top: 50%;
      //         margin-top: -6px;
      //       }
      //     }

      //     .m-item:last-child span::before {
      //       display: none;
      //     }
      //   }
      .m-ul {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .m-item {
          height: 20px;
          box-sizing: border-box;
          line-height: 20px;
          border-right: 1px solid #ddd;
          text-align: center;
          padding: 0px 10px;
        }

        .m-item:last-child {
          border-right: none;
        }
      }

      .m-txt {
        width: 500px;
        height: 50px;
        // border: 1px solid #e1251b;
        text-align: center;

        span {
          font-size: 14px;
          margin-right: 10px;
          color: #282828;
        }

        a {
          font-size: 13px;
        }
      }
    }
  }
}

.floatWindow {
  position: fixed;
  right: 0;
  bottom: 15%;
  width: 60px;
  z-index: 99;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);

  .list {
    .item {
      position: relative;
      width: 100%;
      height: 60px;
      text-align: center;
      font-size: 12px;
      color: #5c5c5c;
      padding: 12px 0;

      &:hover {
        color: #e93323;
      }

      .iconfont {
        margin-bottom: 10px;
        font-size: 25px;
      }

      & ~ .item {
        &:before {
          position: absolute;
          content: " ";
          width: 48px;
          height: 1px;
          background-color: #f0f0f0;
          top: 0;
          left: 50%;
          margin-left: -24px;
        }
      }

      .itemCon {
        right: 100%;
        position: absolute;
        top: 0;
        padding-right: 10px;

        .ewm {
          width: 140px;
          border: 1px solid #eeeeee;
          background-color: #fff;
          padding: 8px 6px;

          .tip {
            font-size: 14px;
            color: #666;
            margin-top: 6px;
          }

          .pictrue {
            width: 126px;
            height: 126px;
            vertical-align: middle;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .arrow {
              position: absolute;
              right: -16px;
              top: 10px;
              width: 0px;
              height: 0px;
              border: 8px solid transparent;
              border-left-color: #eee;

              &:before {
                position: absolute;
                left: -8px;
                top: -7px;
                content: "";
                width: 0px;
                height: 0px;
                border: 7px solid transparent;
                border-left-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>