import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/home',
        children: [{
                path: '/login',
                name: 'Login',
                component: () =>
                    import ( /* webpackChunkName: "Login" */ '../views/Login/Login.vue')
            },
            // home主页
            {
                path: '/home',
                name: 'pcHome',
                component: () =>
                    import ( /* webpackChunkName: "pcHome" */ '../views/pcHome/index.vue')
            },
            {
                path: '/store/join',
                name: 'StoreJoin',
                component: () =>
                    import ( /* webpackChunkName: "StoreJoin" */ '../views/StoreJoin/storeJoin.vue')
            },
            {
                path: '/store/join/step',
                name: 'JoinStep',
                component: () =>
                    import ( /* webpackChunkName: "JoinStep" */ '../views/StoreJoin/JoinStep.vue'),
                children: [{
                        path: '/store/join/step_1',
                        name: 'JoinStep_1',
                        component: () =>
                            import ( /* webpackChunkName: "JoinStep_1" */ '../views/StoreJoin/components/JoinStep_1.vue')
                    },
                    {
                        path: '/store/join/step_2',
                        name: 'JoinStep_2',
                        component: () =>
                            import ( /* webpackChunkName: "JoinStep_2" */ '../views/StoreJoin/components/JoinStep_2.vue')
                    },
                    {
                        path: '/store/join/step_3',
                        name: 'JoinStep_3',
                        component: () =>
                            import ( /* webpackChunkName: "JoinStep_3" */ '../views/StoreJoin/components/JoinStep_3.vue')
                    },
                    {
                        path: '/store/join/step_4',
                        name: 'JoinStep_4',
                        component: () =>
                            import ( /* webpackChunkName: "JoinStep_4" */ '../views/StoreJoin/components/JoinStep_4.vue')
                    }
                ]
            },
            // 商品详情
            {
                path: '/store/goods',
                name: 'goodsDetails',
                component: () =>
                    import ( /* webpackChunkName: "goodsDetails" */ '../views/goods/goodsDetails.vue')
            },
            // 立即购买提交
            {
                path: '/home/buynow',
                name: 'buynow',
                component: () =>
                    import ( /* webpackChunkName: "buynow" */ '../views/order/buynow.vue')
            },
            //  购物车
            {
                path: '/home/shoppingCart',
                name: 'shoppingCart',
                component: () =>
                    import ( /* webpackChunkName: "shoppingCart" */ '../views/shoppingCart/index.vue')
            },
            //  商品列表页
            {
                path: '/home/goodsList',
                name: 'goodsList',
                component: () =>
                    import ( /* webpackChunkName: "goodsList" */ '../views/goods/goodsList.vue')
            },
            //  商品搜索
            {
                path: '/home/search',
                name: 'search',
                component: () =>
                    import ( /* webpackChunkName: "goodsList" */ '../views/goods/searchList.vue')
            },
            //  商品列表页
            {
                path: '/home/addToCart',
                name: 'addToCart',
                component: () =>
                    import ( /* webpackChunkName: "addToCart" */ '../views/shoppingCart/addToCart.vue')
            },
            // 订单确认
            {
                path: '/home/orderConfirm',
                name: 'orderConfirm',
                component: () =>
                    import ( /* webpackChunkName: "addToCart" */ '../views/order/orderConfirm.vue')
            },
            //支付
            {
                path: '/home/payment',
                name: 'payment',
                component: () =>
                    import ( /* webpackChunkName: "payment" */ '../views/payment/index.vue')
            },
            //wx支付
            {
                path: '/home/wxPay',
                name: 'wxPay',
                component: () =>
                    import ( /* webpackChunkName: "wxPay" */ '../views/payment/wxPay.vue')
            },
            //error错误提示
            {
                path: '/home/error',
                name: 'error',
                component: () =>
                    import ( /* webpackChunkName: "error" */ '../views/errorPage/index.vue')
            },
            //订单详情
            {
                path: '/home/orderDetails',
                name: 'orderDetails',
                component: () =>
                    import ( /* webpackChunkName: "orderDetails" */ '../views/order/orderDetails.vue')
            },
            // 用户评价
            {
                path: '/user/evaluation',
                name: 'userEvaluation',
                component: () =>
                    import ( /* webpackChunkName: "userEvaluation" */ '../views/order/userEvaluation.vue')
            },
            // 申请售后
            {
                path: '/home/applyService',
                name: 'applyService',
                component: () =>
                    import ( /* webpackChunkName: "applyService" */ '../views/order/applyService.vue')
            },
            // 已评价
            {
                path: '/user/haveEvaluation',
                name: 'userHaveEvaluation',
                component: () =>
                    import ( /* webpackChunkName: "applyService" */ '../views/order/userHaveEvaluation.vue')
            },
            // 店铺首页
            {
                path: '/store/all/list',
                name: 'allGoodsList',
                component: () =>
                    import ( /* webpackChunkName: "allGoodsList" */ '../views/storeHome/allGoodsList.vue')
            },
            // 店铺全部商品页面
            {
                path: '/store/home',
                name: 'storeHome',
                component: () =>
                    import ( /* webpackChunkName: "storeHome" */ '../views/storeHome/index.vue')
            },
            //售后详情
            {
                path: "/user/refund/details",
                name: "refundDetails",
                component: () =>
                    import ( /* webpackChunkName: "refundDetails" */ '../views/user/components/refundDetails.vue')
            },
            // 填写退货物流单号
            {
                path: '/user/returns/logistics',
                name: 'returnsLogistics',
                component: () =>
                    import ( /* webpackChunkName: "returnsLogistics" */ '../views/user/components/returnsLogistics.vue')
            },
            // 文章列表
            {
                path: '/store/articleg',
                name: 'article',
                component: () =>
                    import ( /* webpackChunkName: "article" */ '../views/article/index.vue')
            },
            {
                path: '/user',
                component: () =>
                    import ( /* webpackChunkName: "User" */ '../views/user/index.vue'),
                children: [
                    { path: "", redirect: { name: "userInfo" } },
                    // 1.账户管理
                    {
                        path: "/user/center",
                        name: "userInfo",
                        component: () =>
                            import ( /* webpackChunkName: "userInfo" */ '../views/user/components/userInfo.vue')
                    },
                    // 2.账户安全
                    {
                        path: "/user/safe",
                        name: "userSafe",
                        component: () =>
                            import ( /* webpackChunkName: "userSafe" */ '../views/user/components/userSafe.vue')
                    },
                    {
                        path: "safe/auth",
                        name: "userSafeAuth",
                        component: () =>
                            import ( /* webpackChunkName: "userSafeAuth" */ '../views/user/components/safeAuth.vue')
                    },
                    // 3.我的订单
                    {
                        path: "/user/orderList",
                        name: "orderList",
                        component: () =>
                            import ( /* webpackChunkName: "orderList" */ '../views/user/components/orderList.vue')
                    },
                    // 5.我的余额
                    {
                        path: "/user/balance",
                        name: "myBalance",
                        component: () =>
                            import ( /* webpackChunkName: "myBalance" */ '../views/user/components/myBalance.vue')
                    },
                    // 6.我的收藏
                    {
                        path: "/user/collect",
                        name: "userCollect",
                        component: () =>
                            import ( /* webpackChunkName: "userCollect" */ '../views/user/components/userCollect.vue')
                    },
                    // 7.地址管理
                    {
                        path: "/user/address",
                        name: "userAddress",
                        component: () =>
                            import ( /* webpackChunkName: "userAddress" */ '../views/user/components/userAddress.vue')
                    },
                    //8.售后服务
                    {
                        path: "/user/afterSales/service",
                        name: "afterSalesService",
                        component: () =>
                            import ( /* webpackChunkName: "afterSalesService" */ '../views/user/components/afterSalesService.vue')
                    }
                ]
            },
        ]
    },
    {
        path: '/home/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/about/about.vue')
    },
    {
        path: '/home/admin_login',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "adminLogin" */ '../views/about/adminLogin.vue')
    }
]



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/') return next()
    if (to.path === '/login') return next()
    if (to.path === '/store/join') return next()
    if (to.path === '/home') return next()
    if (to.path === '/home/about') return next()
    if (to.path === '/home/error') return next()
    if (to.path === '/store/goods') return next()
    if (to.path === '/store/all/list') return next()
    if (to.path === '/store/home') return next()
    if (to.path === '/home/search') return next()
    if (to.path === '/home/addToCart') return next()
    if (to.path === '/home/admin_login') return next()
        // 获取token
    const tokenStr = localStorage.getItem('sefpc_token')
        //   console.log(tokenStr)
    if (!tokenStr) return next('/login')
    next()
})

export default router