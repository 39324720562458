import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: '' || localStorage.getItem('sefpc_userName'),
    userid: '' || localStorage.getItem('sefpc_userid'),
    token: '' || localStorage.getItem('sefpc_token'),
    userAccount: '' || localStorage.getItem('sefpc_userAccount'),
  },
  mutations: {
    setUserName(state, userName) {
      state.userName = userName;
      localStorage.setItem('sefpc_userName', userName);
    },
    setUserId(state, userid) {
      state.userid = userid;
      localStorage.setItem('sefpc_userid', userid);
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('sefpc_token', token);
    },
    setUserAccount(state, userAccount) {
      state.userAccount = userAccount
      localStorage.setItem('sefpc_userAccount', userAccount);
    }
  },
  actions: {},
  modules: {}
})