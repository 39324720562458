import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
// collapse 展开折叠
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import {
    Container,
    Header,
    Main,
    Footer,
    Button,
    Steps,
    Step,
    Icon,
    Card,
    Input,
    Form,
    FormItem,
    Message,
    MessageBox,
    Radio,
    RadioGroup,
    Cascader,
    DatePicker,
    Upload,
    Dialog,
    Select,
    Option,
    Aside,
    Col,
    Row,
    Checkbox,
    InputNumber,
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    Tabs,
    TabPane,
    PageHeader,
    Rate,
    Table,
    TableColumn,
    Loading,
    Pagination,
    Timeline,
    TimelineItem,
    Image

} from 'element-ui'

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Button)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Icon)
Vue.use(Card)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Col)
Vue.use(Aside)
Vue.use(Row)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Alert)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(PageHeader)
Vue.use(Rate)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Image)

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;